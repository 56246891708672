<template>
	<div class="hero">
		<div class="hero__slider" ref="glide">
			<div data-glide-el="track" class="glide__track">
				<div class="glide__slides">
					<div
						v-for="(image, index) in aSlides"
						:key="'glide__slide' + index"
						class="glide__slide"
					>
						<img :src="image" alt="hero slide" />
					</div>
				</div>
			</div>

			<div class="glide__bullets" data-glide-el="controls[nav]">
				<button type="button" class="glide__bullet glide__bullet--light"
					v-for="index in aSlides.length"
					:key="'glide__bullet' + index"
					:data-glide-dir="'=' + (index - 1)"
				></button>
			</div>
		</div>


		<img
			class="hero__logo"
			width="100"
			src="@/assets/logo.png"
			srcset="@/assets/logo@2x.png 2x, @/assets/logo@3x.png 3x"
			alt="dating way"
		/>

		<div class="container-xl">
			<div class="row">
				<div class="col-12">
					<h1 class="hero__title">Looking <br /> for a Couple?</h1>
					
					<p class="hero__subtitle">We are ready to help you</p>

					<button type="button" class="custom-btn wide" @click="$emit('showModal')">Create an account</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Glide from '@glidejs/glide';

export default {
	name: 'hero',

	data: function ()
	{
		return {
			aSlides: Object.freeze([
				require('@/assets/hero/1.jpg'),
				require('@/assets/hero/2.jpg'),
				require('@/assets/hero/3.jpg')
			])
		};
	},

	mounted: function ()
	{
		this.$nextTick(this.init);
	},

	beforeDestroy: function ()
	{
		this.destroy();
	},

	methods: {
		init: function ()
		{
			var option = {
					type: 'slider',
					perView: 1,
					gap: 0,
					keyboard: false,
					swipeThreshold: 10,
					dragThreshold: 30,
					autoplay: 5000,
					animationDuration: 700,
					animationTimingFunc: 'cubic-bezier(0,0,0.2,1)'
				};

			var oSlider = this.slider = new Glide(this.$refs.glide, option);

			oSlider.mount();
		},

		destroy: function ()
		{
			if (this.slider && this.slider.destroy)
			{
				this.slider.destroy();
				this.slider = null;
			}
		}
	}
}
</script>

<style lang="scss">
.hero
{
	position: relative;
	display: flex;
	align-items: center;
	min-height: 100vh;
	padding: 50px 0;
	text-align: center;
	color: #FFF;

	&__slider
	{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		img
		{
			display: block;
			width: 100%;
			height: 100vh;
			object-fit: cover;
		}
	}

	&__logo
	{
		position: absolute;
		top: 15px;
		left: 15px;
		z-index: 1;
	}

	&__title
	{
		line-height: 0.9;
		font-size: 5.5rem;
	}

	&__subtitle
	{
		margin-top: 40px;
		margin-bottom: 45px;
		line-height: 1.2;
		font-size: 2.5rem;
		font-family: 'Source Serif Pro', serif;
	}

	.container::before
	{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.custom-btn
	{
		max-width: 280px;
	}

	.glide
	{
		&__slides
		{
			display: block;
			width: auto !important;
			transform: none !important;
		}

		&__slide
		{
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			transition: opacity 700ms cubic-bezier(0,0,0.2,1);

			&:first-child
			{
				position: relative;
			}

			&--active
			{
				opacity: 1;
				z-index: 1;
			}
		}

		&__bullets
		{
			position: absolute;
			bottom: 30px;
			left: 0;
			right: 0;
			z-index: 1;
		}
	}

	@media (min-width: 576px)
	{
		&__logo
		{
			top: 40px;
		}

		&__title
		{
			font-size: 7rem;
		}
	}

	@media (max-width: 767px)
	{
		&__logo
		{
			width: 75px;
			height: auto;
		}
	}

	@media (min-width: 768px)
	{
		&__title
		{
			font-size: 9rem;
		}

		&__subtitle
		{
			font-size: 4rem;
		}
	}

	@media (min-width: 992px)
	{
		&__title
		{
			font-size: 12rem;
		}
	}

	@media (min-width: 1200px)
	{
		&__logo
		{
			margin-left: calc((100% - 1170px) / 2);
		}

		&__title
		{
			font-size: 15rem;
		}

		&__subtitle
		{
			font-size: 5rem;
		}
	}
}
</style>
